<template>
  <v-main id="layout">
    <template v-if="$vuetify.display.mobile">
      <v-app-bar class="not-print" :elevation="0" color="white">
        <v-toolbar-title class="not-print d-flex align-center">
          <AppIcon name="hugeicons:arrow-left-01" size="24" @click="handleClosePrint()" />
          {{ $route.meta.title }}
        </v-toolbar-title>
      </v-app-bar>
      <div class="d-flex align-center justify-center pa-4 ga-2 not-print">
        <div v-if="hidePrintType">
          <v-chip-group @update:modelValue="setPrintType" v-model="printType" mandatory>
            <v-chip label size="small" value="thermal" filter color="primary">
              Térmica
            </v-chip>
            <v-chip label size="small" value="a4" filter color="secondary">
              A4
            </v-chip>
          </v-chip-group>
        </div>
        <div class="not-print" v-if="printType == 'thermal' && hideThermalSize">
          <div>
            <AppSelect @update:modelValue="setThermalSize" :items="items" v-model="thermalSize" label="Tamanho"
              density="compact" />
          </div>
        </div>
        <div class="not-print">
          <AppBtn color="primary" @click="handlePrint()">
            <AppIcon name="hugeicons:printer" size="18" class="mr-2" />Imprimir
          </AppBtn>
        </div>
      </div>
    </template>
    <template v-else>
      <v-app-bar class="not-print" :elevation="0" color="white">
        <v-toolbar-title class="not-print">
          {{ $route.meta.title }}
        </v-toolbar-title>
        <div v-if="hidePrintType">
          <v-chip-group @update:modelValue="setPrintType" v-model="printType" mandatory>
            <v-chip label size="small" value="thermal" filter color="primary">
              Térmica
            </v-chip>
            <v-chip label size="small" value="a4" filter color="secondary">
              A4
            </v-chip>
          </v-chip-group>
        </div>
        <div class="pa-4 not-print">
          <AppBtn color="primary" @click="handlePrint()">
            <AppIcon name="hugeicons:printer" size="18" class="mr-2" /> Imprimir
          </AppBtn>
        </div>
      </v-app-bar>
    </template>
    <v-theme-provider theme="light" with-background>
      <template v-if="!$vuetify.display.mobile">
        <div class="not-print d-flex justify-center mb-5" v-if="printType == 'thermal' && hideThermalSize">
          <div>
            <AppSelect style="width: 200px" @update:modelValue="setThermalSize" :items="items" v-model="thermalSize"
              label="Tamanho" density="compact" />
          </div>
        </div>
      </template>
      <slot />
    </v-theme-provider>
  </v-main>
</template>

<script setup>
const appStore = useAppStore();
const route = useRoute();

const layoutOptions = computed(() => route.meta?.layoutOptions);

const hidePrintType = computed(
  () => layoutOptions.value?.hidePrintType != true || false
);

const hideThermalSize = computed(
  () => layoutOptions.value?.hideThermalSize != true || false
);

const { printType, thermalSize } = storeToRefs(appStore);

const setPrintType = (type) => {
  appStore.setPrintType(type);
};

const setThermalSize = (size) => {
  appStore.setThermalSize(size);
};

const handlePrint = () => {
  window.print();
};

const handleClosePrint = () => {
  window.close();
};

const items = [
  { title: "80mm", value: "80mm" },
  { title: "58mm", value: "58mm" },
];

</script>


<style lang="scss">
#layout {
  @media print {
    padding: 0 !important;

    .not-print {
      display: none !important;
    }
  }
}
</style>